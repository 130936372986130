<template>
  <div class="page">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="questionnaire" service="credit" backlink="/credit/ref-credit"/>
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "c_s4p10_text_1" | localize }}</p>
          <form @submit.prevent="submitHandler">
            <div class="form__group">
              <v-select
                v-model="amount"
                @blur="$v.amount.$touch()"
                @change="checkForm($v.amount)"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                :class="{ invalid: $v.amount.$dirty && !$v.amount.required }"
                :placeholder="'select' | localize"
                :menu-props="{ offsetY: true }"
                append-icon="icon-arrow-down"
                ref="amount"
                outlined
              ></v-select>
            </div>
            <button type="submit" class="button button__form" :class="{ button__form_disabled: !success }">
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'

const credit = VueCookies.get('credit') || {}

export default {
  name: 'Credit_amount',
  components: { Backlink },
  mixins: [formMixin, commonMixin],
  data: () => ({ amount: credit.amount || null }),
  validations: { amount: { required } },
  methods: {
    submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }
      credit.amount = this.amount
      this.$cookies.set('credit', credit)
      this.$router.push({
        name: 'About Credits',
        params: { amount: this.amount }
      })
    }
  }
}
</script>
